<template>
  
</template>

<script>
export default {
    created(){
        localStorage.removeItem("user")
        setTimeout(() => {
            window.location = '/login'
        }, 1000);
    }
}
</script>

<style>

</style>